export const KFJ_LETS_LIVE_YOUTUBE_VIDEO_ID = "THCqcYAMBo8";

// Navbar Items
export const enum NavbarItem {
  HOME = "Home",
  ABOUT = "About",
  PROJECTS = "Projects",
  MEDIA = "Media",
}
export const NAVBAR_LINKS = [
  NavbarItem.HOME,
  NavbarItem.ABOUT,
  NavbarItem.PROJECTS,
  NavbarItem.MEDIA,
];

// Navbar Filter
export const NAVBAR_FILTER = {
  [NavbarItem.HOME]: [
    "card-introduction",
    "card-linkedin",
    "card-github",
    "card-music",
    "card-workout",
    "card-uiuc",
    "card-marist",
    "card-medium",
    "card-devpost",
    "card-about",
    "card-axios",
    "card-quantum",
    "card-yuhm",
    "card-sdfs",
    "card-maple-juice",
    "card-fox-radar",
    "card-grepy",
    "card-charlie",
    "card-stratus",
  ],
  [NavbarItem.ABOUT]: ["card-introduction", "card-about"],
  [NavbarItem.PROJECTS]: [
    "card-axios",
    "card-quantum",
    "card-yuhm",
    "card-sdfs",
    "card-maple-juice",
    "card-fox-radar",
    "card-grepy",
    "card-charlie",
    "card-stratus",
  ],
  [NavbarItem.MEDIA]: [
    "card-linkedin",
    "card-github",
    "card-music",
    "card-workout",
    "card-medium",
    "card-devpost",
  ],
};

// React Grid Layouts
export const LAYOUTS = {
  [NavbarItem.HOME]: {
    lg: [
      { i: "card-introduction", x: 0, y: 0, w: 2, h: 32 },
      { i: "card-linkedin", x: 2, y: 0, w: 1, h: 16 },
      { i: "card-github", x: 3, y: 0, w: 1, h: 16 },
      { i: "card-music", x: 2, y: 16, w: 1, h: 16 },
      { i: "card-workout", x: 3, y: 16, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 32, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 32, w: 1, h: 16 },
      { i: "card-medium", x: 0, y: 48, w: 1, h: 16 },
      { i: "card-devpost", x: 1, y: 48, w: 1, h: 16 },
      { i: "card-about", x: 2, y: 32, w: 2, h: 32 },
      { i: "card-axios", x: 0, y: 64, w: 4, h: 32 },
      { i: "card-quantum", x: 0, y: 96, w: 3, h: 16 },
      { i: "card-yuhm", x: 3, y: 96, w: 1, h: 32 },
      { i: "card-sdfs", x: 0, y: 112, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 112, w: 1, h: 16 },
      { i: "card-fox-radar", x: 2, y: 112, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 128, w: 2, h: 16 },
      { i: "card-charlie", x: 3, y: 128, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 144, w: 4, h: 32 },
    ],
    md: [
      { i: "card-introduction", x: 0, y: 0, w: 2, h: 32 },
      { i: "card-linkedin", x: 2, y: 0, w: 1, h: 16 },
      { i: "card-github", x: 2, y: 16, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 32, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 32, w: 1, h: 16 },
      { i: "card-medium", x: 2, y: 32, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 48, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 48, w: 1, h: 16 },
      { i: "card-devpost", x: 2, y: 48, w: 1, h: 16 },
      { i: "card-about", x: 0, y: 64, w: 3, h: 26 },
      { i: "card-axios", x: 0, y: 90, w: 3, h: 24 },
      { i: "card-quantum", x: 0, y: 114, w: 3, h: 16 },
      { i: "card-sdfs", x: 0, y: 130, w: 1, h: 16 },
      { i: "card-maple-juice", x: 0, y: 146, w: 1, h: 16 },
      { i: "card-fox-radar", x: 1, y: 130, w: 1, h: 32 },
      { i: "card-yuhm", x: 2, y: 130, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 162, w: 2, h: 16 },
      { i: "card-charlie", x: 2, y: 162, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 178, w: 3, h: 24 },
    ],
    sm: [
      { i: "card-introduction", x: 0, y: 0, w: 2, h: 32 },
      { i: "card-linkedin", x: 0, y: 32, w: 1, h: 16 },
      { i: "card-github", x: 1, y: 32, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 48, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 48, w: 1, h: 16 },
      { i: "card-about", x: 0, y: 64, w: 2, h: 32 },
      { i: "card-uiuc", x: 0, y: 96, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 96, w: 1, h: 16 },
      { i: "card-medium", x: 0, y: 112, w: 1, h: 16 },
      { i: "card-devpost", x: 1, y: 112, w: 1, h: 16 },
      { i: "card-axios", x: 0, y: 128, w: 2, h: 16 },
      { i: "card-quantum", x: 0, y: 144, w: 2, h: 16 },
      { i: "card-sdfs", x: 0, y: 160, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 160, w: 1, h: 16 },
      { i: "card-fox-radar", x: 0, y: 176, w: 1, h: 32 },
      { i: "card-yuhm", x: 1, y: 176, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 208, w: 1, h: 16 },
      { i: "card-charlie", x: 1, y: 208, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 234, w: 2, h: 16 },
    ],
    xs: [
      { i: "card-introduction", h: 49 },
      { i: "card-linkedin", h: 20 },
      { i: "card-github", h: 20 },
      { i: "card-music", h: 20 },
      { i: "card-workout", h: 20 },
      { i: "card-about", h: 48 },
      { i: "card-uiuc", h: 20 },
      { i: "card-marist", h: 20 },
      { i: "card-medium", h: 20 },
      { i: "card-devpost", h: 20 },
      { i: "card-axios", h: 11 },
      { i: "card-quantum", h: 20 },
      { i: "card-sdfs", h: 20 },
      { i: "card-maple-juice", h: 20 },
      { i: "card-yuhm", h: 42 },
      { i: "card-fox-radar", h: 42 },
      { i: "card-grepy", h: 20 },
      { i: "card-charlie", h: 20 },
      { i: "card-stratus", h: 11 },
    ].map((item, index, arr) => {
      const y = arr.slice(0, index).reduce((acc, cur) => acc + cur.h, 0);
      return { ...item, x: 0, y, w: 1 };
    }),
  },
  [NavbarItem.ABOUT]: {
    lg: [
      { i: "card-introduction", x: 0, y: 0, w: 2, h: 32 },
      { i: "card-about", x: 2, y: 0, w: 2, h: 32 },
      { i: "card-linkedin", x: 0, y: 32, w: 1, h: 16 },
      { i: "card-github", x: 1, y: 32, w: 1, h: 16 },
      { i: "card-music", x: 2, y: 32, w: 1, h: 16 },
      { i: "card-workout", x: 3, y: 32, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 48, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 48, w: 1, h: 16 },
      { i: "card-medium", x: 2, y: 48, w: 1, h: 16 },
      { i: "card-devpost", x: 3, y: 48, w: 1, h: 16 },
      { i: "card-axios", x: 0, y: 64, w: 4, h: 32 },
      { i: "card-quantum", x: 0, y: 96, w: 3, h: 16 },
      { i: "card-yuhm", x: 3, y: 96, w: 1, h: 32 },
      { i: "card-sdfs", x: 0, y: 112, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 112, w: 1, h: 16 },
      { i: "card-fox-radar", x: 2, y: 112, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 128, w: 2, h: 16 },
      { i: "card-charlie", x: 3, y: 128, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 144, w: 4, h: 32 },
    ],
    md: [
      { i: "card-about", x: 0, y: 0, w: 3, h: 26 },
      { i: "card-introduction", x: 0, y: 26, w: 2, h: 32 },
      { i: "card-linkedin", x: 2, y: 26, w: 1, h: 16 },
      { i: "card-github", x: 2, y: 42, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 58, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 58, w: 1, h: 16 },
      { i: "card-medium", x: 2, y: 58, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 74, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 74, w: 1, h: 16 },
      { i: "card-devpost", x: 2, y: 74, w: 1, h: 16 },
      { i: "card-axios", x: 0, y: 90, w: 3, h: 24 },
      { i: "card-quantum", x: 0, y: 114, w: 3, h: 16 },
      { i: "card-sdfs", x: 0, y: 130, w: 1, h: 16 },
      { i: "card-maple-juice", x: 0, y: 146, w: 1, h: 16 },
      { i: "card-fox-radar", x: 1, y: 130, w: 1, h: 32 },
      { i: "card-yuhm", x: 2, y: 130, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 162, w: 2, h: 16 },
      { i: "card-charlie", x: 2, y: 162, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 178, w: 3, h: 24 },
    ],
    sm: [
      { i: "card-about", x: 0, y: 0, w: 2, h: 32 },
      { i: "card-introduction", x: 0, y: 32, w: 2, h: 32 },
      { i: "card-linkedin", x: 0, y: 64, w: 1, h: 16 },
      { i: "card-github", x: 1, y: 64, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 80, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 80, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 96, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 96, w: 1, h: 16 },
      { i: "card-medium", x: 0, y: 112, w: 1, h: 16 },
      { i: "card-devpost", x: 1, y: 112, w: 1, h: 16 },
      { i: "card-axios", x: 0, y: 128, w: 2, h: 16 },
      { i: "card-quantum", x: 0, y: 144, w: 2, h: 16 },
      { i: "card-sdfs", x: 0, y: 160, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 160, w: 1, h: 16 },
      { i: "card-fox-radar", x: 0, y: 176, w: 1, h: 32 },
      { i: "card-yuhm", x: 1, y: 176, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 208, w: 1, h: 16 },
      { i: "card-charlie", x: 1, y: 208, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 234, w: 2, h: 16 },
    ],
    xs: [
      { i: "card-about", h: 48 },
      { i: "card-introduction", h: 49 },
      { i: "card-linkedin", h: 20 },
      { i: "card-github", h: 20 },
      { i: "card-music", h: 20 },
      { i: "card-workout", h: 20 },
      { i: "card-uiuc", h: 20 },
      { i: "card-marist", h: 20 },
      { i: "card-medium", h: 20 },
      { i: "card-devpost", h: 20 },
      { i: "card-axios", h: 11 },
      { i: "card-quantum", h: 20 },
      { i: "card-sdfs", h: 20 },
      { i: "card-maple-juice", h: 20 },
      { i: "card-yuhm", h: 42 },
      { i: "card-fox-radar", h: 42 },
      { i: "card-grepy", h: 20 },
      { i: "card-charlie", h: 20 },
      { i: "card-stratus", h: 11 },
    ].map((item, index, arr) => {
      const y = arr.slice(0, index).reduce((acc, cur) => acc + cur.h, 0);
      return { ...item, x: 0, y, w: 1 };
    }),
  },
  [NavbarItem.PROJECTS]: {
    lg: [
      { i: "card-axios", x: 0, y: 0, w: 4, h: 32 },
      { i: "card-quantum", x: 0, y: 32, w: 3, h: 16 },
      { i: "card-yuhm", x: 3, y: 32, w: 1, h: 32 },
      { i: "card-sdfs", x: 0, y: 48, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 48, w: 1, h: 16 },
      { i: "card-fox-radar", x: 2, y: 48, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 64, w: 2, h: 16 },
      { i: "card-charlie", x: 3, y: 64, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 80, w: 4, h: 32 },
      { i: "card-introduction", x: 0, y: 112, w: 2, h: 32 },
      { i: "card-linkedin", x: 2, y: 112, w: 1, h: 16 },
      { i: "card-github", x: 3, y: 112, w: 1, h: 16 },
      { i: "card-music", x: 2, y: 128, w: 1, h: 16 },
      { i: "card-workout", x: 3, y: 128, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 144, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 144, w: 1, h: 16 },
      { i: "card-medium", x: 0, y: 160, w: 1, h: 16 },
      { i: "card-devpost", x: 1, y: 160, w: 1, h: 16 },
      { i: "card-about", x: 2, y: 144, w: 2, h: 32 },
    ],
    md: [
      { i: "card-axios", x: 0, y: 0, w: 3, h: 24 },
      { i: "card-quantum", x: 0, y: 24, w: 3, h: 16 },
      { i: "card-sdfs", x: 0, y: 40, w: 1, h: 16 },
      { i: "card-maple-juice", x: 0, y: 56, w: 1, h: 16 },
      { i: "card-fox-radar", x: 1, y: 40, w: 1, h: 32 },
      { i: "card-yuhm", x: 2, y: 40, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 72, w: 2, h: 16 },
      { i: "card-charlie", x: 2, y: 72, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 88, w: 3, h: 24 },
      { i: "card-introduction", x: 0, y: 112, w: 2, h: 32 },
      { i: "card-linkedin", x: 2, y: 112, w: 1, h: 16 },
      { i: "card-github", x: 2, y: 128, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 144, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 144, w: 1, h: 16 },
      { i: "card-medium", x: 2, y: 144, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 160, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 160, w: 1, h: 16 },
      { i: "card-devpost", x: 2, y: 160, w: 1, h: 16 },
      { i: "card-about", x: 0, y: 176, w: 3, h: 26 },
    ],
    sm: [
      { i: "card-axios", x: 0, y: 0, w: 2, h: 16 },
      { i: "card-quantum", x: 0, y: 16, w: 2, h: 16 },
      { i: "card-sdfs", x: 0, y: 32, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 32, w: 1, h: 16 },
      { i: "card-fox-radar", x: 0, y: 48, w: 1, h: 32 },
      { i: "card-yuhm", x: 1, y: 48, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 80, w: 1, h: 16 },
      { i: "card-charlie", x: 1, y: 80, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 96, w: 2, h: 16 },
      { i: "card-introduction", x: 0, y: 112, w: 2, h: 32 },
      { i: "card-linkedin", x: 0, y: 144, w: 1, h: 16 },
      { i: "card-github", x: 1, y: 144, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 160, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 160, w: 1, h: 16 },
      { i: "card-about", x: 0, y: 176, w: 2, h: 32 },
      { i: "card-uiuc", x: 0, y: 208, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 208, w: 1, h: 16 },
      { i: "card-medium", x: 0, y: 224, w: 1, h: 16 },
      { i: "card-devpost", x: 1, y: 224, w: 1, h: 16 },
    ],
    xs: [
      { i: "card-axios", h: 11 },
      { i: "card-quantum", h: 20 },
      { i: "card-sdfs", h: 20 },
      { i: "card-maple-juice", h: 20 },
      { i: "card-yuhm", h: 42 },
      { i: "card-fox-radar", h: 42 },
      { i: "card-grepy", h: 20 },
      { i: "card-charlie", h: 20 },
      { i: "card-stratus", h: 11 },
      { i: "card-introduction", h: 49 },
      { i: "card-linkedin", h: 20 },
      { i: "card-github", h: 20 },
      { i: "card-music", h: 20 },
      { i: "card-workout", h: 20 },
      { i: "card-about", h: 48 },
      { i: "card-uiuc", h: 20 },
      { i: "card-marist", h: 20 },
      { i: "card-medium", h: 20 },
      { i: "card-devpost", h: 20 },
    ].map((item, index, arr) => {
      const y = arr.slice(0, index).reduce((acc, cur) => acc + cur.h, 0);
      return { ...item, x: 0, y, w: 1 };
    }),
  },
  [NavbarItem.MEDIA]: {
    lg: [
      { i: "card-linkedin", x: 0, y: 0, w: 1, h: 16 },
      { i: "card-github", x: 1, y: 0, w: 1, h: 16 },
      { i: "card-music", x: 2, y: 0, w: 1, h: 16 },
      { i: "card-workout", x: 3, y: 0, w: 1, h: 16 },
      { i: "card-medium", x: 0, y: 16, w: 1, h: 16 },
      { i: "card-devpost", x: 1, y: 16, w: 1, h: 16 },
      { i: "card-uiuc", x: 2, y: 16, w: 1, h: 16 },
      { i: "card-marist", x: 3, y: 16, w: 1, h: 16 },
      { i: "card-introduction", x: 0, y: 32, w: 2, h: 32 },
      { i: "card-about", x: 2, y: 32, w: 2, h: 32 },
      { i: "card-axios", x: 0, y: 64, w: 4, h: 32 },
      { i: "card-quantum", x: 0, y: 96, w: 3, h: 16 },
      { i: "card-yuhm", x: 3, y: 96, w: 1, h: 32 },
      { i: "card-sdfs", x: 0, y: 112, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 112, w: 1, h: 16 },
      { i: "card-fox-radar", x: 2, y: 112, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 128, w: 2, h: 16 },
      { i: "card-charlie", x: 3, y: 128, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 144, w: 4, h: 32 },
    ],
    md: [
      { i: "card-linkedin", x: 0, y: 0, w: 1, h: 16 },
      { i: "card-github", x: 1, y: 0, w: 1, h: 16 },
      { i: "card-medium", x: 2, y: 0, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 16, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 16, w: 1, h: 16 },
      { i: "card-devpost", x: 2, y: 16, w: 1, h: 16 },
      { i: "card-introduction", x: 0, y: 32, w: 2, h: 32 },
      { i: "card-uiuc", x: 2, y: 32, w: 1, h: 16 },
      { i: "card-marist", x: 2, y: 48, w: 1, h: 16 },
      { i: "card-about", x: 0, y: 64, w: 3, h: 26 },
      { i: "card-axios", x: 0, y: 90, w: 3, h: 24 },
      { i: "card-quantum", x: 0, y: 114, w: 3, h: 16 },
      { i: "card-sdfs", x: 0, y: 130, w: 1, h: 16 },
      { i: "card-maple-juice", x: 0, y: 146, w: 1, h: 16 },
      { i: "card-fox-radar", x: 1, y: 130, w: 1, h: 32 },
      { i: "card-yuhm", x: 2, y: 130, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 162, w: 2, h: 16 },
      { i: "card-charlie", x: 2, y: 162, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 178, w: 3, h: 24 },
    ],
    sm: [
      { i: "card-linkedin", x: 0, y: 0, w: 1, h: 16 },
      { i: "card-github", x: 1, y: 0, w: 1, h: 16 },
      { i: "card-music", x: 0, y: 16, w: 1, h: 16 },
      { i: "card-workout", x: 1, y: 16, w: 1, h: 16 },
      { i: "card-medium", x: 0, y: 32, w: 1, h: 16 },
      { i: "card-devpost", x: 1, y: 32, w: 1, h: 16 },
      { i: "card-uiuc", x: 0, y: 48, w: 1, h: 16 },
      { i: "card-marist", x: 1, y: 48, w: 1, h: 16 },
      { i: "card-introduction", x: 0, y: 64, w: 2, h: 32 },
      { i: "card-about", x: 0, y: 96, w: 2, h: 32 },
      { i: "card-axios", x: 0, y: 128, w: 2, h: 16 },
      { i: "card-quantum", x: 0, y: 144, w: 2, h: 16 },
      { i: "card-sdfs", x: 0, y: 160, w: 1, h: 16 },
      { i: "card-maple-juice", x: 1, y: 160, w: 1, h: 16 },
      { i: "card-fox-radar", x: 0, y: 176, w: 1, h: 32 },
      { i: "card-yuhm", x: 1, y: 176, w: 1, h: 32 },
      { i: "card-grepy", x: 0, y: 208, w: 1, h: 16 },
      { i: "card-charlie", x: 1, y: 208, w: 1, h: 16 },
      { i: "card-stratus", x: 0, y: 234, w: 2, h: 16 },
    ],
    xs: [
      { i: "card-linkedin", h: 20 },
      { i: "card-github", h: 20 },
      { i: "card-music", h: 20 },
      { i: "card-workout", h: 20 },
      { i: "card-medium", h: 20 },
      { i: "card-devpost", h: 20 },
      { i: "card-uiuc", h: 20 },
      { i: "card-marist", h: 20 },
      { i: "card-introduction", h: 49 },
      { i: "card-about", h: 48 },
      { i: "card-axios", h: 11 },
      { i: "card-quantum", h: 20 },
      { i: "card-sdfs", h: 20 },
      { i: "card-maple-juice", h: 20 },
      { i: "card-yuhm", h: 42 },
      { i: "card-fox-radar", h: 42 },
      { i: "card-grepy", h: 20 },
      { i: "card-charlie", h: 20 },
      { i: "card-stratus", h: 11 },
    ].map((item, index, arr) => {
      const y = arr.slice(0, index).reduce((acc, cur) => acc + cur.h, 0);
      return { ...item, x: 0, y, w: 1 };
    }),
  },
};

export const APPLE_RED = "rgb(255, 105, 97)";
export const APPLE_ORANGE = "rgb(255, 179, 64)";
export const APPLE_BLUE = "rgb(64, 156, 255)";
export const APPLE_GREY = "rgb(36, 36, 38)";
export const APPLE_BLACK = "rgb(28, 28, 30)";
export const APPLE_WHITE = "rgb(255, 255, 255)";
export const APPLE_GREEN = "rgb(48, 209, 88)";

//  Colors

export const COLOR_G50: string = "#E3FCEF";

export const COLOR_N0: string = "#FFFFFF";
export const COLOR_N30: string = "#EBECF0";
export const COLOR_N70: string = "#42526E";
export const COLOR_N900: string = "#091E42";

export const COLOR_P100: string = "#0052CC";

export const COLOR_R50: string = "#FF5630";

export const COLOR_T50: string = "rgba(9, 30, 66, 0.08)";

export const GRID: number = 8;
export const BORDER_RADIUS: number = 2;

export const DS_BORDER_BRAND: string = "#0C66E4";
export const DS_TEXT: string = "#172B4D";
export const DS_BACKGROUND_SELECTED = "#E9F2FF";
export const DS_SURFACE_SUNKEN: string = "#F4F5F7";
export const DS_TEXT_LOW_EMPHASIS: string = "#626f86";
export const DS_TEXT_SUBTLE: string = "#44546F";
export const DS_NEUTRAL_BACKGROUND_HOVERD: string = "#091E4224";
