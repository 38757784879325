import { FC, ReactNode, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

export const MOBILE_CARD_WIDTH = 248;

export enum CardStyle {
  SQUARE_1 = 'square-1',
  SQUARE_2 = 'square-2',
  RECTANGLE_H_2_V_1 = 'rectangle-h-2',
  RECTANGLE_H_3_V_1 = 'rectangle-h-3',
  RECTANGLE_H_3_V_2 = 'rectangle-h-3-v-2',
  RECTANGLE_H_4_V_2 = 'rectangle-h-4-v-2',
  RECTANGLE_H_1_V_2 = 'rectangle-h-1-v-2',
}

export function getCardStyle(
  cardStyle: CardStyle,
  squareSide: number = 196,
  gutterV: number = 12,
  gutterH: number = 12
) {
  switch (cardStyle) {
    case CardStyle.SQUARE_1:
      return {
        height: squareSide,
        width: squareSide,
      };
    case CardStyle.SQUARE_2:
      return {
        height: squareSide * 2 + gutterV,
        width: squareSide * 2 + gutterH,
      };
    case CardStyle.RECTANGLE_H_2_V_1:
      return {
        height: squareSide,
        width: squareSide * 2 + gutterH,
      };
    case CardStyle.RECTANGLE_H_3_V_1:
      return {
        height: squareSide,
        width: squareSide * 3 + gutterH * 2,
      };
    case CardStyle.RECTANGLE_H_3_V_2:
      return {
        height: squareSide * 2 + gutterV,
        width: squareSide * 3 + gutterH * 2,
      };
    case CardStyle.RECTANGLE_H_4_V_2:
      return {
        height: squareSide * 2 + gutterV,
        width: squareSide * 4 + gutterH * 3,
      };
    case CardStyle.RECTANGLE_H_1_V_2:
      return {
        height: squareSide * 2 + gutterV,
        width: squareSide,
      };
    default:
      return {
        height: squareSide,
        width: squareSide,
      };
  }
}

export type StyledCardProps = {
  height: number;
  width: number;
  backgroundColor: string;
  isGrabbing?: boolean;
  padding?: string;
  mdWidth?: number;
  mdHeight?: number;
  smWidth?: number;
  smHeight?: number;
  xsWidth?: number;
  xsHeight?: number;
};

export const StyledCard = styled.div`
  height: ${({ height }: StyledCardProps) => height}px;
  width: ${({ width }: StyledCardProps) => width}px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 32px;
  padding: ${({ padding }: StyledCardProps) => padding};
  background: ${({ backgroundColor }: StyledCardProps) => backgroundColor};

  cursor: ${({ isGrabbing }: StyledCardProps) =>
    isGrabbing ? 'grabbing' : 'grab'};

  opacity: 0;
  transform: translateY(30px);
  transition: box-shadow 300ms ease-in, width 300ms ease-in,
    opacity 600ms ease-in-out, transform 600ms ease-in-out;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.1) 0px 5px 24px 0px;
  }

  @media (max-width: 840px) {
    width: ${({ mdWidth, width }: StyledCardProps) => mdWidth || width}px;
    height: ${({ mdHeight, height }: StyledCardProps) => mdHeight || height}px;
  }

  @media (max-width: 768px) {
    width: ${({ smWidth, width }: StyledCardProps) => smWidth || width}px;
    height: ${({ smHeight, height }: StyledCardProps) => smHeight || height}px;
  }

  @media (max-width: 456px) {
    width: ${({ xsWidth, width }: StyledCardProps) => xsWidth || width}px;
    height: ${({ xsHeight, height }: StyledCardProps) => xsHeight || height}px;
  }
`;

export interface CardProps {
  cardStyle: CardStyle;
  padding?: string;
  backgroundColor: string;
  isGrabbing: boolean;
  children?: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  mdWidth?: number;
  mdHeight?: number;
  smWidth?: number;
  smHeight?: number;
  xsWidth?: number;
  xsHeight?: number;
}

export const Card: FC<CardProps> = (props: CardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.style.opacity = '1';
      cardRef.current.style.transform = 'translateY(30px)';
      setTimeout(() => {
        if (cardRef.current) {
          cardRef.current.style.opacity = '1';
          cardRef.current.style.transform = 'translateY(0)';
        }
      }, 0);
    }
  }, []);
  const {
    cardStyle,
    padding,
    backgroundColor,
    isGrabbing,
    children,
    onMouseEnter,
    onMouseLeave,
    mdWidth,
    mdHeight,
    smWidth,
    smHeight,
    xsWidth,
    xsHeight,
  } = props;
  return (
    <StyledCard
      ref={cardRef}
      {...getCardStyle(cardStyle)}
      padding={padding}
      backgroundColor={backgroundColor}
      isGrabbing={isGrabbing}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      mdWidth={mdWidth}
      mdHeight={mdHeight}
      smWidth={smWidth}
      smHeight={smHeight}
      xsWidth={xsWidth || 248}
      xsHeight={xsHeight || 248}
    >
      {children}
    </StyledCard>
  );
};
