import styled from "@emotion/styled";

interface StyledIconButtonProps {
  isDragging?: boolean;
}

const StyledIconButton = styled.button`
  position: relative;
  width: 36px;
  height: 36px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  border: none;
  border-radius: 18px;
  cursor: ${({ isDragging }: StyledIconButtonProps) =>
    isDragging ? "grabbing" : "pointer"};
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(240, 242, 248) 0px 0px 0px 2px;

  transition: all 0.2s;
  animation: moveUp 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  backface-visibility: hidden;

  &:hover {
    outline: none;
    transform: translateY(-10%);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0%);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }

  &::after {
    content: "";
    display: inline-block;
    border-radius: 18px;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.4s ease-out;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:hover::after {
    transform: scaleX(1.75) scaleY(1.75);
    opacity: 0;
  }
`;

type CardIconButtonProps = {
  style: React.CSSProperties;
  onClick: () => void;
  isDragging?: boolean;
};

export const IconButton = ({
  style,
  onClick,
  isDragging,
}: CardIconButtonProps) => {
  return (
    <StyledIconButton
      className="icon-button"
      style={style}
      onClick={onClick}
      isDragging={isDragging}
    >
      <svg
        id="Arrow.7"
        xmlns="http://www.w3.org/2000/svg"
        width="18.256"
        height="18.256"
        viewBox="0 0 18.256 18.256"
      >
        <g id="Group_7" data-name="Group 7" transform="translate(5.363 5.325)">
          <path
            id="Path_10"
            data-name="Path 10"
            d="M14.581,7.05,7.05,14.581"
            transform="translate(-7.05 -7.012)"
            fill="none"
            stroke="#0D1117"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
          <path
            id="Path_11"
            data-name="Path 11"
            d="M10,7l5.287.037.038,5.287"
            transform="translate(-7.756 -7)"
            fill="none"
            stroke="#0D1117"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
        </g>
        <path
          id="Path_12"
          data-name="Path 12"
          d="M0,0H18.256V18.256H0Z"
          fill="none"
        ></path>
      </svg>
    </StyledIconButton>
  );
};
