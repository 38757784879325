import { useState } from "react";
import styled from "@emotion/styled";

import { APPLE_BLUE, APPLE_WHITE } from "../../constants";

import { Card, CardStyle } from "./Card";
import {
  MarginVert20,
  StyledHeader1,
  StyledHeader2,
  StyledParagraph,
  StyledLink,
} from "../Typography/Typography";
import { TextButton } from "../Buttons/TextButton";

const StyleContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
`;

const CenterHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledImage = styled.img`
  height: 64px;
  width: 64px;
  margin-right: 16px;
  margin-top: -4px;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardIntroductionProps {
  isGrabbing: boolean;
}

export const CardIntroduction = ({ isGrabbing }: CardIntroductionProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      cardStyle={CardStyle.SQUARE_2}
      xsHeight={625}
      backgroundColor={APPLE_WHITE}
      isGrabbing={isGrabbing}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyleContainer>
        <CenterHead className="card-intro-top">
          <Head className="card-top-me">
            <StyledImage src="/images/headshot.png" alt="Bitmoji" />
            <div>
              <StyledHeader1>Hi, I'm Alex</StyledHeader1>
              <StyledHeader2>Software Engineer</StyledHeader2>
            </div>
          </Head>
        </CenterHead>
        <StyledParagraph>
          I'm driven by an insatiable curiosity to explore a wide range of
          domains — where no question remains unanswered (or at least,
          untested!).
        </StyledParagraph>
        <MarginVert20 />
        <StyledParagraph style={{ color: APPLE_BLUE }}>
          #Frontend #Backend #Cloud Computing #Mobile #CI/CD #Cybersecurity #IDE
          Plugins #Quantum Computing #Distributed Systems #Data Analytics #Game
          Development #Embedded Systems
        </StyledParagraph>
        {/* <CardIntroDescription className="card-intro-description">
          I love to challenge myself through workouts and casual chess, teaming
          up for success in ultimate frisbee, and unwinding with friends through
          music, video games, and more.
        </CardIntroDescription> */}
        <StyledButtonContainer>
          <TextButton
            className="axios-text-button"
            style={{
              position: "absolute",
              bottom: 16,
              left: 16,
            }}
            onClick={() => window.open("https://drive.google.com/file/d/16aK95EBq9GM4u9rlI0kYi-o-YIvAMoAC/view?usp=sharing")}
            hoverWidth={`94px`}
            isHovered={isHovered}
            tools={[<StyledLink>Resume</StyledLink>]}
          />
        </StyledButtonContainer>
      </StyleContainer>
    </Card>
  );
};
