import { useState } from "react";
import styled from "@emotion/styled";

import { APPLE_BLACK } from "../../constants";

import { Card, CardStyle, getCardStyle } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const StyledImage = styled.img`
  width: 100%;
  margin-top: -8px;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardStratusProps {
  isGrabbing: boolean;
}

export const CardStratus = ({ isGrabbing }: CardStratusProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      cardStyle={CardStyle.RECTANGLE_H_4_V_2}
      mdWidth={getCardStyle(CardStyle.RECTANGLE_H_3_V_2).width}
      mdHeight={300}
      smWidth={getCardStyle(CardStyle.RECTANGLE_H_2_V_1).width}
      smHeight={getCardStyle(CardStyle.SQUARE_1).height}
      xsHeight={131}
      backgroundColor={APPLE_BLACK}
      isGrabbing={isGrabbing}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledImage
        src="https://storage.googleapis.com/g-i-f-s/s-t-r-a-t-u-s-d-e-m-o.gif"
        alt="stratus-demo-gif"
      />
      <StyledButtonContainer>
        <TextButton
          className="stratus-text-button"
          style={{
            position: "absolute",
            bottom: 16,
            left: 16,
          }}
          onClick={() => window.open("https://demo-self-ten.vercel.app/")}
          hoverWidth={`86px`}
          isHovered={isHovered}
          tools={[<StyledLink>Stratus</StyledLink>]}
        />
      </StyledButtonContainer>
    </Card>
  );
};
