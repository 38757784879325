import { useState } from "react";
import styled from "@emotion/styled";

import { APPLE_WHITE } from "../../constants";

import { Card, CardStyle, MOBILE_CARD_WIDTH } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  height: 100%;
  padding: 4px;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardMapleJuiceProps {
  isGrabbing: boolean;
}

export const CardMapleJuice = ({ isGrabbing }: CardMapleJuiceProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      cardStyle={CardStyle.SQUARE_1}
      xsHeight={MOBILE_CARD_WIDTH}
      backgroundColor={APPLE_WHITE}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isGrabbing={isGrabbing}
    >
      <StyledContainer>
        <StyledImage
          src="/images/maple-juice.png"
          alt="maple-juice-mapreduce-framework-thumbnail"
        />
        <StyledButtonContainer>
          <TextButton
            className="maple-juice-text-button"
            style={{
              position: "absolute",
              bottom: 16,
              left: 16,
            }}
            onClick={() =>
              window.open("https://github.com/alexbadia1/Maple-Juice")
            }
            hoverWidth={`120px`}
            isHovered={isHovered}
            tools={[<StyledLink>Maple Juice</StyledLink>]}
          />
        </StyledButtonContainer>
      </StyledContainer>
    </Card>
  );
};
