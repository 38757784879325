import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";

interface CardTextButtonProps {
  isHovered: boolean;
  hoverWidth: string;
  isDragging?: boolean;
}

const CardTextButton = styled.button`
  position: relative;
  width: 36px;
  height: 36px;

  display: inline-flex;
  justify-content: flex-end;
  align-items: center;

  text-decoration: none;
  border: 1px solid rgb(240, 242, 248);
  border-radius: 18px;
  cursor: ${({ isDragging }: CardTextButtonProps) =>
    isDragging ? "grabbing" : "pointer"};
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(240, 242, 248) 0px 0px 0px 2px;

  transition: width 200ms ease-out;
  animation: moveUp 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  backface-visibility: hidden;

  &:hover {
    outline: none;
    transform: translateY(-10%);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0%);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }

  &::after {
    content: "";
    display: inline-block;
    border-radius: 18px;
    width: ${({ isHovered, hoverWidth }: CardTextButtonProps) =>
      isHovered ? hoverWidth : "36px"};
    height: 36px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 200ms ease-out;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:hover::after {
    transform: scaleX(1.25) scaleY(1.75);
    opacity: 0;
  }
`;

const StyledToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

const StyledTool = styled.div`
  opacity: 0;

  &.reveal {
    transition: opacity 300ms;
    opacity: 1;
  }

  &.hide {
    transition: opacity 25ms;
    opacity: 0;
  }
`;

type TextButtonProps = {
  className: string;
  style: React.CSSProperties;
  tools: ReactElement[];
  hoverWidth: string;
  onClick: () => void;
  isHovered: boolean;
  isDragging?: boolean;
};

export const TextButton = (props: TextButtonProps) => {
  const {
    className,
    style,
    tools,
    hoverWidth,
    onClick,
    isHovered,
    isDragging,
  } = props;
  const [showTools, setShowTools] = useState(Array(tools.length).fill(false));

  useEffect(() => {
    let timeouts: any[] = [];
    if (isHovered) {
      // Show each tool one at a time on hover
      timeouts = tools.map((_, index) =>
        setTimeout(
          () =>
            setShowTools((prev) => {
              const newShowTools = [...prev];
              newShowTools[index] = true;
              return newShowTools;
            }),
          (index + 1) * 100
        )
      );
    } else {
      // Hide each tool one at a time when leaving hover
      timeouts = tools.map((_, index) =>
        setTimeout(
          () =>
            setShowTools((prev) => {
              const newShowTools = [...prev];
              newShowTools[index] = false;
              return newShowTools;
            }),
          (tools.length - index) * 2
        )
      );
    }
    return () => timeouts.forEach(clearTimeout); // Clean up timeouts
  }, [isHovered, tools]);

  return (
    <CardTextButton
      className={`text-button ${className}`}
      hoverWidth={hoverWidth}
      isDragging={isDragging}
      style={{ width: isHovered ? hoverWidth : "36px", ...style }}
      onClick={onClick}
      isHovered={isHovered}
    >
      <StyledToolbar>
        {tools.map((tool, index) => (
          <StyledTool
            key={`styled-tool-${index}`}
            className={showTools[index] ? "reveal" : "hide"}
          >
            {tool}
          </StyledTool>
        ))}
      </StyledToolbar>
      <svg
        id="Arrow.7"
        xmlns="http://www.w3.org/2000/svg"
        width="18.256"
        height="18.256"
        viewBox="0 0 18.256 18.256"
        style={{
          marginRight: "8px",
          zIndex: 10,
          minWidth: "18.256px",
          minHeight: "18.256px",
        }}
      >
        <g id="Group_7" data-name="Group 7" transform="translate(5.363 5.325)">
          <path
            id="Path_10"
            data-name="Path 10"
            d="M14.581,7.05,7.05,14.581"
            transform="translate(-7.05 -7.012)"
            fill="none"
            stroke="#0D1117"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_11"
            data-name="Path 11"
            d="M10,7l5.287.037.038,5.287"
            transform="translate(-7.756 -7)"
            fill="none"
            stroke="#0D1117"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
        <path
          id="Path_12"
          data-name="Path 12"
          d="M0,0H18.256V18.256H0Z"
          fill="none"
        />
      </svg>
    </CardTextButton>
  );
};
