import { useState } from "react";
import styled from "styled-components";

import { APPLE_WHITE } from "../../constants";

import { Card, CardStyle } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const StyledImage = styled.img`
  height: 100%;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardYuhmProps {
  isGrabbing: boolean;
}

export const CardYuhm = ({ isGrabbing }: CardYuhmProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      cardStyle={CardStyle.RECTANGLE_H_1_V_2}
      xsHeight={534}
      backgroundColor={APPLE_WHITE}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isGrabbing={isGrabbing}
    >
      <StyledImage
        src="https://storage.googleapis.com/g-i-f-s/y-u-h-m.gif"
        alt="yuhm-demo-gif"
      />
      <StyledButtonContainer>
        <TextButton
          className="yuhm-text-button"
          style={{
            position: "absolute",
            bottom: 16,
            left: 16,
          }}
          onClick={() => window.open("https://alexbadia1.github.io/Yuhm/#/")}
          hoverWidth={`82px`}
          isHovered={isHovered}
          tools={[<StyledLink>Yuhm.</StyledLink>]}
        />
      </StyledButtonContainer>
    </Card>
  );
};
