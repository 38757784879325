import styled from "@emotion/styled";


const TEXT_ALIGN = `
  text-align: justify;
`;

const IGNORE_INTERACTION = `
  -webkit-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
`;


export const StyledHeader1 = styled.h1`
  display: inline-block;
  font-family: "Bogart", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: rgb(13, 17, 23);

  ${IGNORE_INTERACTION}
`;

export const StyledHeader2 = styled.h1`
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: rgba(13, 17, 23, 0.7);

  ${IGNORE_INTERACTION}
`;

export const StyledParagraph = styled.p`
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgb(13, 17, 23);

  ${IGNORE_INTERACTION}
`;

export const StyledBlockQoute = styled.p`
  display: inline-block;
  font-family: "Bogart-Light-Italic", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: rgb(13, 17, 23);

  ${IGNORE_INTERACTION}
  ${TEXT_ALIGN}
`;

export const StyledLink = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;

  color: color: rgb(13, 17, 23);
`;

export const MarginVert20 = styled.div`
  height: 20px;
`;

export const MarginVert16 = styled.div`
  height: 16px;
`;

export const MarginVert8 = styled.div`
  height: 8px;
`;