import styled from "@emotion/styled";

import { APPLE_BLACK } from "../../constants";

import { Card, CardStyle, MOBILE_CARD_WIDTH } from "./Card";

function getWorkoutForDate(date: Date) {
  const daysSinceEpoch = Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
  const cycleLength = 4;
  const workouts = ["P U S H", "P U L L", "L E G S", "R E S T"];
  const workoutIndex = daysSinceEpoch % cycleLength;
  return workouts[workoutIndex];
}

function getWorkoutHtmlContent(workout: string) {
  switch (workout) {
    case "P U S H":
      return (
        <>
          Bench Press &bull; Shoulder Press &bull; Incline Bench Press &bull;
          Skull Crushers &bull; Lateral Raises &bull; Pec Deck
        </>
      );
    case "P U L L":
      return (
        <>
          Deadlift &bull; Pullups &bull; Rows &bull; Bicep Curls &bull; Reverse
          Pec Deck &bull; Face Pulls
        </>
      );
    case "L E G S":
      return (
        <>
          High Bar Squats &bull; Leg Press &bull; Hip Thrusts &bull; Hamstring
          Curls &bull; Leg Extensions &bull; Calf Raises
        </>
      );
    case "R E S T":
      return (
        <>
          Go for a walk or light jog, stretch, eat well, recover, and get a lot
          of sleep!
        </>
      );
    default:
      return null;
  }
}

const ContainerColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
`;

// Apple Fitness Appbar

const StyledFitnessAppbar = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 8px;
`;

const StyledFitnessAppbarTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: rgb(255, 55, 95);

  margin-left: 24px;
`;

// Fitness Logo and Title

const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WorkoutLogo = styled.img`
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

const WokoutTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-left: 12px;
`;

const WorkoutSubtitle = styled.div`
  font-size: 12px;
  color: white;
`;

const WorkoutTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: normal;

  visibility: visible;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;

  color: rgb(255, 55, 95);
`;

const WorkoutDescription = styled.div`
  font-size: 12px;
  padding: 0 16px;
  margin-top: 8px;
  margin-bottom: 12px;
  color: white;
  text-align: center;
`;

interface CardWorkoutProps {
  isGrabbing: boolean;
}

export const CardWorkout = ({ isGrabbing }: CardWorkoutProps) => {
  const today = new Date();
  const workout = getWorkoutForDate(today);
  return (
    <Card
      cardStyle={CardStyle.SQUARE_1}
      xsHeight={MOBILE_CARD_WIDTH}
      backgroundColor={APPLE_BLACK}
      isGrabbing={isGrabbing}
    >
      <ContainerColumn>
        <StyledFitnessAppbar>
          <StyledFitnessAppbarTitle> Workout </StyledFitnessAppbarTitle>
        </StyledFitnessAppbar>
        <ContainerRow>
          <WorkoutLogo
            src="/images/fitness_logo.png"
            alt="fitness_logo"
            width={56}
            height={56}
            onDrag={(e) => e.preventDefault()}
          />
          <WokoutTitleContainer>
            <WorkoutSubtitle> Today </WorkoutSubtitle>
            <WorkoutTitle> {workout} </WorkoutTitle>
          </WokoutTitleContainer>
        </ContainerRow>
        <WorkoutDescription>
          {getWorkoutHtmlContent(workout)}
        </WorkoutDescription>
      </ContainerColumn>
    </Card>
  );
};
