import { useState } from "react";
import styled from "@emotion/styled";

import { APPLE_WHITE } from "../../constants";

import { Card, CardStyle, getCardStyle } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const StyledImage = styled.img`
  width: 100%;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardAxiosProps {
  isGrabbing: boolean;
}

export const CardAxios = ({ isGrabbing }: CardAxiosProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      cardStyle={CardStyle.RECTANGLE_H_4_V_2}
      mdWidth={getCardStyle(CardStyle.RECTANGLE_H_3_V_2).width}
      mdHeight={300}
      smWidth={getCardStyle(CardStyle.RECTANGLE_H_2_V_1).width}
      smHeight={getCardStyle(CardStyle.SQUARE_1).height}
      xsHeight={131}
      backgroundColor={APPLE_WHITE}
      isGrabbing={isGrabbing}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledImage
        src="https://storage.googleapis.com/g-i-f-s/a-x-i-o-s.gif"
        alt="axios-demo-gif"
      />
      <StyledButtonContainer>
        <TextButton
          className="axios-text-button"
          style={{
            position: "absolute",
            bottom: 16,
            left: 16,
          }}
          onClick={() =>
            window.open("https://alexbadia1.github.io/AxiOS-Compiler/#/")
          }
          hoverWidth={`80px`}
          isHovered={isHovered}
          tools={[<StyledLink>AxiOS</StyledLink>]}
        />
      </StyledButtonContainer>
    </Card>
  );
};
