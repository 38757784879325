import { useState } from "react";
import styled from "@emotion/styled";

import { APPLE_WHITE } from "../../constants";

import { Card, CardStyle, MOBILE_CARD_WIDTH, getCardStyle } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const StyledImage = styled.img`
  width: 100%;

  @media (max-width: 768px) {
    width: 275%;
  }
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardGrepyProps {
  isGrabbing: boolean;
}

export const CardGrepy = ({ isGrabbing }: CardGrepyProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      cardStyle={CardStyle.RECTANGLE_H_2_V_1}
      smWidth={getCardStyle(CardStyle.SQUARE_1).width}
      xsHeight={MOBILE_CARD_WIDTH}
      backgroundColor={APPLE_WHITE}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isGrabbing={isGrabbing}
    >
      <StyledImage src="/images/DFA.png" />
      <StyledButtonContainer>
        <TextButton
          className="grepy-text-button"
          style={{
            position: "absolute",
            bottom: 16,
            left: 16,
          }}
          onClick={() =>
            window.open("https://github.com/alexbadia1/Grepy/tree/main")
          }
          hoverWidth={`80px`}
          isHovered={isHovered}
          tools={[<StyledLink>Grepy</StyledLink>]}
        />
      </StyledButtonContainer>
    </Card>
  );
};
