import { useState } from "react";
import styled from "@emotion/styled";

import { APPLE_WHITE } from "../../constants";

import { Card, CardStyle } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardFoxRadarProps {
  isGrabbing: boolean;
}

export const CardFoxRadar = ({ isGrabbing }: CardFoxRadarProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      cardStyle={CardStyle.RECTANGLE_H_1_V_2}
      xsHeight={534}
      backgroundColor={APPLE_WHITE}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isGrabbing={isGrabbing}
    >
      <StyledImage
        src="https://storage.googleapis.com/g-i-f-s/f-o-x-r-a-d-a-r.gif"
        alt="fox-radar-demo-gif"
      />
      <StyledButtonContainer>
        <TextButton
          className="yuhm-text-button"
          style={{
            position: "absolute",
            bottom: 16,
            left: 16,
          }}
          onClick={() => window.open("https://github.com/alexbadia1/fox-radar")}
          hoverWidth={`106px`}
          isHovered={isHovered}
          tools={[<StyledLink>Fox Radar</StyledLink>]}
        />
      </StyledButtonContainer>
    </Card>
  );
};
