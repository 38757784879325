import styled from "@emotion/styled";
import { APPLE_ORANGE, APPLE_RED } from "../../constants";
import { Card, CardStyle, MOBILE_CARD_WIDTH } from "./Card";

const EducationText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 12px;
  color: #ffffff;

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

const EducationTextTitle = styled.div`
  font-size: 12px;
  font-weight: 800;

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

const EducationTextSubtitle = styled.div`
  font-style: italic;

  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

const CardUIUCLogo = styled.img`
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  height: 64px;
  width: 64px;
  margin-bottom: 8px;

  border-style: none;
`;

interface CardUIUCProps {
  isGrabbing: boolean;
}

export const CardUIUC = ({ isGrabbing }: CardUIUCProps) => {
  return (
    <Card
      cardStyle={CardStyle.SQUARE_1}
      xsHeight={MOBILE_CARD_WIDTH}
      padding="0px 16px"
      backgroundColor={APPLE_ORANGE}
      isGrabbing={isGrabbing}
    >
      <CardUIUCLogo
        src="/images/uiuc-logo.png"
        onDrag={(e) => e.preventDefault()}
      />
      <EducationText className="card-education-text">
        <EducationTextTitle className="card-education-text-title">
          University of Illinois at Urbana-Champaign
        </EducationTextTitle>
        <EducationTextSubtitle className="card-education-text-subtitle">
          Master's
        </EducationTextSubtitle>
        <EducationTextSubtitle className="card-education-text-subtitle">
          Computer Science
        </EducationTextSubtitle>
        <div className="card-education-text-description">2023 - 2024</div>
      </EducationText>
    </Card>
  );
};

const CardMaristLogo = styled.img`
  border-style: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 64px;
  width: 64px;
  margin-bottom: 8px;
`;

interface CardMaristProps {
  isGrabbing: boolean;
}

export const CardMarist = ({ isGrabbing }: CardMaristProps) => {
  return (
    <Card
      cardStyle={CardStyle.SQUARE_1}
      xsHeight={MOBILE_CARD_WIDTH}
      padding="0px 16px"
      backgroundColor={APPLE_RED}
      isGrabbing={isGrabbing}
    >
      <CardMaristLogo
        src="/images/marist-logo.png"
        onDrag={(e) => e.preventDefault()}
      />
      <EducationText className="card-education-text">
        <EducationTextTitle className="card-education-text-title">
          Marist College
        </EducationTextTitle>
        <EducationTextSubtitle className="card-education-text-subtitle">
          Bachelor's
        </EducationTextSubtitle>
        <EducationTextSubtitle className="card-education-text-subtitle">
          Computer Science, Cybersecurity
        </EducationTextSubtitle>
        <div className="card-education-text-description">2018 - 2022</div>
      </EducationText>
    </Card>
  );
};
