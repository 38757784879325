import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { NAVBAR_LINKS, NavbarItem } from "../../constants";
import { StyledLink } from "../Typography/Typography";

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 136px;
`;

const NavbarLinks = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: 23px;
  padding: 5px;

  background: rgba(0, 0, 0, 0.04);

  opacity: 0;
  transition: opacity 600ms ease-in-out;
`;

const NavbarLink = styled.div`
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 50px;
  padding: 0px 16px;

  transition: opacity 0.3s ease 0s;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.25s ease 0s;
  }
`;

const NavbarHighlight = styled.div`
  content: "";
  position: absolute;
  padding: 1rem 1.25rem;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  z-index: -1;
  transition: transform 0.3s ease-out, width 0.3s ease-out;
`;

interface NavbarProps {
  onClick: (name: NavbarItem) => void;
}

export const Navbar: FC<NavbarProps> = ({ onClick }: NavbarProps) => {
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const highlightRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement | null>(null);

  const navRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (navRef.current) {
      navRef.current.style.opacity = "0";
      setTimeout(() => {
        if (navRef.current) {
          navRef.current.style.opacity = "1";
        }
      }, 0);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFirstLoad(false);
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const highlighter = highlightRef.current;
    const firstElement = document.getElementById("navbar-link-0");
    const activeElement = document.getElementById(`navbar-link-${activeIndex}`);

    if (highlighter && firstElement && activeElement) {
      // highlighter.style.left = `${activeElement.offsetLeft}px`;
      highlighter.style.transform = `translateX(${
        activeElement.offsetLeft - firstElement.offsetLeft
      }px)`;
      highlighter.style.width = `${activeElement.offsetWidth}px`;
    }
  }, [activeIndex]);

  const handleClick = (index: number) => {
    setActiveIndex(index);
    onClick(NAVBAR_LINKS[index]);
  };

  return (
    <NavbarContainer className="navbar">
      <NavbarLinks className="navbar-links" ref={navRef}>
        <NavbarHighlight
          ref={highlightRef}
          className={`${
            isFirstLoad ? "transition_deactivate" : "transition_activate"
          }`}
        />
        {NAVBAR_LINKS.map((item: string, index: number) => (
          <NavbarLink
            key={`navbar-link-${index}`}
            id={`navbar-link-${index}`}
            className={`${index === activeIndex ? "active" : ""}`}
            onClick={() => handleClick(index)}
          >
            <StyledLink>{item}</StyledLink>
          </NavbarLink>
        ))}
      </NavbarLinks>
    </NavbarContainer>
  );
};
