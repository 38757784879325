// External libraries
import React, { useState } from "react";
import styled from "@emotion/styled";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";

// CSS imports
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// Absolute imports
import { LAYOUTS, NAVBAR_FILTER, NavbarItem } from "../constants";

// Components
import { Navbar } from "../Components/Navbar/Navbar";
import { CardAbout } from "../Components/Cards/CardAbout";
import { CardAxios } from "../Components/Cards/CardAxios";
import { CardCharlie } from "../Components/Cards/CardCharlie";
import { CardFoxRadar } from "../Components/Cards/CardFoxRadar";
import { CardGrepy } from "../Components/Cards/CardGrepy";
import { CardIntroduction } from "../Components/Cards/CardIntroduction";
import { CardMapleJuice } from "../Components/Cards/CardMapleJuice";
import { CardMarist, CardUIUC } from "../Components/Cards/CardEducation";
import { CardMusic } from "../Components/Cards/CardMusic";
import { CardQuantum } from "../Components/Cards/CardQuantum";
import { CardSdfs } from "../Components/Cards/CardSdfs";
import {
  CardSocialDevpost,
  CardSocialGitHub,
  CardSocialLinkedIn,
  CardSocialMedium,
} from "../Components/Cards/CardSocial";
import { CardStratus } from "../Components/Cards/CardStratus";
import { CardWorkout } from "../Components/Cards/CardWorkout";
import { CardYuhm } from "../Components/Cards/CardYuhm";

const ResponsiveReactGridContainer = styled.div`
  width: 100%;
  max-width: 842px;
  margin: auto;

  @media (max-width: 842px) {
    /* 896px - 200 [4th col] - 16 [margin] - 27 */
    max-width: 636px;
  }

  @media (max-width: 768px) {
    max-width: 428px;
  }

  @media (max-width: 456px) {
    width: 272px;
  }
`;

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Home: React.FC = () => {
  const [layout, setLayout] = useState(LAYOUTS[NavbarItem.HOME]);
  const [draggingKey, setDraggingKey] = useState<string | null>(null);
  const [navbarFilter, setNavbarFilter] = useState<string[]>(
    NAVBAR_FILTER[NavbarItem.HOME]
  );
  const isGrabbing = draggingKey !== null;

  const handleDragStart = (layout: Layout[], oldItem: Layout) => {
    setDraggingKey(oldItem.i);
  };

  const handleDragStop = (layout: Layout[], oldItem: Layout) => {
    setDraggingKey(null);
  };

  const rumble = (key: string) => {
    const cssStyle: { [key: string]: any } = {};

    if (!navbarFilter.includes(key)) {
      cssStyle["opacity"] = 0.25;
    } else {
      cssStyle["opacity"] = 1;
    }

    if (draggingKey === null) {
      return cssStyle;
    }

    if (draggingKey === key) {
      cssStyle["transform"] = "scale(1.03)";
      return cssStyle;
    }

    cssStyle["animation"] = [
      "card-stratus",
      "card-axios",
      "card-grepy",
      "card-fox-radar",
      "card-yuhm",
      "card-quantum",
    ].includes(key)
      ? `earthquake .225s ${Math.random() * 0.05}s infinite`
      : `rumble .225s ${Math.random() * 0.05}s infinite`;

    return cssStyle;
  };

  return (
    <div>
      <Navbar
        onClick={(navbarItem: NavbarItem) => {
          setLayout(LAYOUTS[navbarItem]);
          setNavbarFilter(NAVBAR_FILTER[navbarItem]);
        }}
      />
      <ResponsiveReactGridContainer>
        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          breakpoints={{ lg: 834, md: 635, sm: 427, xs: 320 }}
          cols={{ lg: 4, md: 3, sm: 2, xs: 1 }}
          rowHeight={1}
          margin={[12, 12]}
          autoSize={true}
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          draggableCancel=".icon-button, .text-button, .ignore-dnd-draggable, .ignore-react-layout-grid"
          isResizable={false}
          isBounded={false}
          useCSSTransforms={false}
          preventCollision={false}
        >
          <div key="card-about" className='tooltip' style={rumble("card-about")}>
            <span className="tooltiptext">Drag me!</span>
            <CardAbout isGrabbing={isGrabbing} />
          </div>
          <div key="card-axios" className='tooltip' style={rumble("card-axios")}>
            <span className="tooltiptext">Drag me!</span>
            <CardAxios isGrabbing={isGrabbing} />
          </div>
          <div key="card-charlie" className='tooltip' style={rumble("card-charlie")}>
            <span className="tooltiptext">Drag me!</span>
            <CardCharlie isGrabbing={isGrabbing} />
          </div>
          <div key="card-devpost" className='tooltip' style={rumble("card-devpost")}>
            <span className="tooltiptext">Drag me!</span>
            <CardSocialDevpost isGrabbing={isGrabbing} />
          </div>
          <div key="card-fox-radar" className='tooltip' style={rumble("card-fox-radar")}>
            <span className="tooltiptext">Drag me!</span>
            <CardFoxRadar isGrabbing={isGrabbing} />
          </div>
          <div key="card-github" className='tooltip' style={rumble("card-github")}>
            <span className="tooltiptext">Drag me!</span>
            <CardSocialGitHub isGrabbing={isGrabbing} />
          </div>
          <div key="card-grepy" className='tooltip' style={rumble("card-grepy")}>
            <span className="tooltiptext">Drag me!</span>
            <CardGrepy isGrabbing={isGrabbing} />
          </div>
          <div key="card-introduction" className='tooltip' style={rumble("card-introduction")}>
            <span className="tooltiptext">Drag me!</span>
            <CardIntroduction isGrabbing={isGrabbing} />
          </div>
          <div key="card-linkedin" className='tooltip' style={rumble("card-linkedin")}>
            <span className="tooltiptext">Drag me!</span>
            <CardSocialLinkedIn isGrabbing={isGrabbing} />
          </div>
          <div key="card-maple-juice" className='tooltip' style={rumble("card-maple-juice")}>
            <span className="tooltiptext">Drag me!</span>
            <CardMapleJuice isGrabbing={isGrabbing} />
          </div>
          <div key="card-marist" className='tooltip' style={rumble("card-marist")}>
            <span className="tooltiptext">Drag me!</span>
            <CardMarist isGrabbing={isGrabbing} />
          </div>
          <div key="card-medium" className='tooltip' style={rumble("card-medium")}>
            <span className="tooltiptext">Drag me!</span>
            <CardSocialMedium isGrabbing={isGrabbing} />
          </div>
          <div key="card-music" className='tooltip' style={rumble("card-music")}>
            <span className="tooltiptext">Drag me!</span>
            <CardMusic isGrabbing={isGrabbing} />
          </div>
          <div key="card-quantum" className='tooltip' style={rumble("card-quantum")}>
            <span className="tooltiptext">Drag me!</span>
            <CardQuantum isGrabbing={isGrabbing} />
          </div>
          <div key="card-sdfs" className='tooltip' style={rumble("card-sdfs")}>
            <span className="tooltiptext">Drag me!</span>
            <CardSdfs isGrabbing={isGrabbing} />
          </div>
          <div key="card-stratus" className='tooltip' style={rumble("card-stratus")}>
            <span className="tooltiptext">Drag me!</span>
            <CardStratus isGrabbing={isGrabbing} />
          </div>
          <div key="card-uiuc" className='tooltip' style={rumble("card-uiuc")}>
            <span className="tooltiptext">Drag me!</span>
            <CardUIUC isGrabbing={isGrabbing} />
          </div>
          <div key="card-workout" className='tooltip' style={rumble("card-workout")}>
            <span className="tooltiptext">Drag me!</span>
            <CardWorkout isGrabbing={isGrabbing} />
          </div>
          <div key="card-yuhm" className='tooltip' style={rumble("card-yuhm")}>
            <span className="tooltiptext">Drag me!</span>
            <CardYuhm isGrabbing={isGrabbing} />
          </div>
        </ResponsiveGridLayout>
      </ResponsiveReactGridContainer>
    </div>
  );
};
