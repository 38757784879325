import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import "./index.css";
import "react-circular-progressbar/dist/styles.css";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { toast } from "react-toastify";

const StyledContainer = styled.div`
  width: 64px;
  height: 64px;
`;

interface StyledButtonProps {
  isDragging?: boolean;
}

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: ${({ isDragging }: StyledButtonProps) =>
    isDragging ? "grabbing" : "pointer"};

  transition: all 0.2s;
  animation: moveUp 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
  backface-visibility: hidden;

  &:hover {
    outline: none;
    transform: translateY(-10%);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0%);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }
`;

interface MusicButtonProps {
  youtubeVideoId: string;
  isDragging?: boolean;
}

export const MusicButton = ({
  youtubeVideoId,
  isDragging,
}: MusicButtonProps) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Load the YouTube IFrame Player API
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    script.async = true;
    script.onerror = () => {
      toast.error(
        "Failed to load YouTube player. Please check if YouTube is blocked."
      );
    };
    document.body.appendChild(script);

    // Hide the player
    (window as any).onYouTubeIframeAPIReady = () => {
      playerRef.current = new (window as any).YT.Player(
        "hidden-youtube-player",
        {
          height: "0",
          width: "0",
          videoId: youtubeVideoId,
          events: {
            onStateChange: onPlayerStateChange,
          },
        }
      );
    };

    // Listen for visibility change events
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (progressInterval.current) clearInterval(progressInterval.current);
    };
  });

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setIsPlaying(false);
    }
  };

  const updateProgress = () => {
    if (playerRef.current && (playerRef.current as any).getDuration) {
      const duration = (playerRef.current as any).getDuration();
      const currentTime = (playerRef.current as any).getCurrentTime();
      const newProgress = (currentTime / duration) * 100;
      setProgress(newProgress);
    }
  };

  const onPlayerStateChange = (event: any) => {
    if (event.data === (window as any).YT.PlayerState.ENDED) {
      setIsPlaying(false);
      setProgress(0);
      if (progressInterval.current) {
        // Clear the interval when the video ends
        clearInterval(progressInterval.current);
      }
    } else if (event.data === (window as any).YT.PlayerState.PLAYING) {
      progressInterval.current = setInterval(updateProgress, 500); // Update the progress every second
    } else {
      if (event.data === (window as any).YT.PlayerState.ERROR) {
        toast.error("An error occurred while trying to play the music.");
      }
      if (progressInterval.current) {
        // Clear the interval when the video is not playing
        clearInterval(progressInterval.current);
      }
    }
  };

  const handleToggle = () => {
    if (isPlaying) {
      (playerRef as any).current.pauseVideo();
      if (progressInterval.current) clearInterval(progressInterval.current); // Clear the interval when the video is paused
    } else {
      (playerRef as any).current.playVideo();
      progressInterval.current = setInterval(updateProgress, 500); // Update the progress every second
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <StyledContainer className="ignore-react-layout-grid">
      <div id="hidden-youtube-player" style={{ display: "none" }}></div>
      <CircularProgressbarWithChildren
        value={progress}
        styles={buildStyles({
          pathColor: "#1ed760",
          trailColor: "transparent",
        })}
        strokeWidth={5}
      >
        <StyledButton onClick={handleToggle} isDragging={isDragging}>
          {isPlaying ? (
            <svg
              width={40}
              height={40}
              data-encore-id="icon"
              role="img"
              aria-hidden="true"
              viewBox="0 0 24 24"
              fill="#1ed760"
              style={{ marginTop: "2px" }}
            >
              <path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z" />
            </svg>
          ) : (
            <svg
              width={48}
              height={48}
              data-encore-id="icon"
              role="img"
              aria-hidden="true"
              viewBox="0 0 24 24"
              fill="#1ed760"
              style={{ marginTop: "2.5px", marginLeft: ".5px" }}
            >
              <path d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm8.75-4.567a.5.5 0 0 0-.75.433v8.268a.5.5 0 0 0 .75.433l7.161-4.134a.5.5 0 0 0 0-.866L9.75 7.433z" />
            </svg>
          )}
        </StyledButton>
      </CircularProgressbarWithChildren>
    </StyledContainer>
  );
};
