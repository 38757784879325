import styled from "@emotion/styled";

import { APPLE_BLACK, KFJ_LETS_LIVE_YOUTUBE_VIDEO_ID } from "../../constants";

import { Card, CardStyle, MOBILE_CARD_WIDTH } from "./Card";
import { MusicButton } from "../Buttons/MusicButton";

// const FKJ_GOLD = "rgb(133, 117, 66)";
// const FKJ_GRAY = "#282828";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;
`;

// Spotify Header
const StyledSpotifyHeader = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 4px;
`;

const StyledSpotifyHeaderTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #1ed760;
`;

// Image and Music Play Button

const ImageContainer = styled.div`
  width: 95%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 4px;
  margin-left: 12px;
`;

const StyledImage = styled.img`
  border-radius: 8px;
`;

// Album

const StyledAlbumContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 12px;
`;

const StyledAlbumTitle = styled.div`
  color: white;

  font-size: 14px;
  font-weight: 900;
  line-height: normal;

  visibility: visible;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
`;

const StyledAlbumSubtitle = styled.div`
  font-size: 12px;
  opacity: 0.5;
  font-weight: 400;
  line-height: normal;

  color: white;
`;

// Song

const StyledSongContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding-bottom: 4px;
`;

const StyledSongNumber = styled.div`
  color: white;

  font-size: 14px;
  opacity: 0.5;
  font-weight: 400;
  line-height: normal;
`;

const StyledSongTitle = styled.div`
  color: white;

  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  visibility: visible;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
`;

interface CardMusicProps {
  isGrabbing: boolean;
}

export const CardMusic = ({ isGrabbing }: CardMusicProps) => {
  return (
    <Card
      cardStyle={CardStyle.SQUARE_1}
      xsHeight={MOBILE_CARD_WIDTH}
      backgroundColor={APPLE_BLACK}
      isGrabbing={isGrabbing}
    >
      <Container>
        <StyledSpotifyHeader>
          <StyledSpotifyHeaderTitle>Music</StyledSpotifyHeaderTitle>
          <div>...</div>
        </StyledSpotifyHeader>
        <ImageContainer>
          <StyledImage
            src="images/fkj-album-cover.jpeg"
            width={56}
            height={56}
          />
          <div style={{ width: 12 }} />
          <MusicButton youtubeVideoId={KFJ_LETS_LIVE_YOUTUBE_VIDEO_ID} />
        </ImageContainer>
        <StyledAlbumContainer>
          <StyledAlbumTitle>V I N C E N T</StyledAlbumTitle>
          <StyledAlbumSubtitle>
            FKJ &bull; 2022 &bull; 14 songs
          </StyledAlbumSubtitle>
        </StyledAlbumContainer>
        <StyledSongContainer>
          <StyledSongNumber>10</StyledSongNumber>
          <div style={{ width: 8 }} />
          <StyledSongTitle>Let's Live</StyledSongTitle>
        </StyledSongContainer>
      </Container>
    </Card>
  );
};
