import { useState } from "react";
import styled from "@emotion/styled";

import { Card, CardStyle, MOBILE_CARD_WIDTH, getCardStyle } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const IBM_DARK = "#21272A";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  height: 90%;
  
  @media (max-width: 768px) {
    transform: translateX(-32%);
  }

  @media (max-width: 456px) {
    height: 55%;
    transform: translateX(-31%);
  }
`;

const StylePaper = styled.img`
  height: 90%;

  @media (max-width: 768px) {
    transform: translateX(-80%);
  }

  @media (max-width: 456px) {
    height: 55%;
    transform: translateX(-75%);
  }
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardQuantumProps {
  isGrabbing: boolean;
}

export const CardQuantum = ({ isGrabbing }: CardQuantumProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      cardStyle={CardStyle.RECTANGLE_H_3_V_1}
      smWidth={getCardStyle(CardStyle.RECTANGLE_H_2_V_1).width}
      xsHeight={MOBILE_CARD_WIDTH}
      backgroundColor={IBM_DARK}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isGrabbing={isGrabbing}
    >
      <Container>
        <StyledImage src="/images/quantum.png" alt="q-sphere" />
        <StylePaper src="/images/quantum-paper.png" alt="q-sphere" />
        <StyledButtonContainer>
          <TextButton
            className="stratus-text-button"
            style={{
              position: "absolute",
              bottom: 16,
              left: 16,
            }}
            onClick={() =>
              window.open(
                "https://ecc.marist.edu/documents/2625221/2667540/QAOA+5+page+paper/1a4563f5-03eb-4eb4-b74f-b1e69ec909f5"
              )
            }
            hoverWidth={`242px`}
            isHovered={isHovered}
            tools={[<StyledLink>Quantum Computing Research</StyledLink>]}
          />
        </StyledButtonContainer>
      </Container>
    </Card>
  );
};
