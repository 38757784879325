import { useState } from "react";
import styled from "@emotion/styled";
import { APPLE_WHITE } from "../../constants";
import { Card, CardStyle, getCardStyle } from "./Card";
import {
  MarginVert8,
  StyledHeader1,
  StyledLink,
  StyledParagraph,
} from "../Typography/Typography";
import { TextButton } from "../Buttons/TextButton";

const StyleContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
`;

const CenterHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardAboutProps {
  isGrabbing: boolean;
}

export const CardAbout = ({ isGrabbing }: CardAboutProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      cardStyle={CardStyle.SQUARE_2}
      mdHeight={326}
      xsHeight={612}
      backgroundColor={APPLE_WHITE}
      isGrabbing={isGrabbing}
      mdWidth={getCardStyle(CardStyle.RECTANGLE_H_3_V_1).width}
      smWidth={getCardStyle(CardStyle.RECTANGLE_H_2_V_1).width}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyleContainer>
        <CenterHead>
          <StyledHeader1>Why Computer Science?</StyledHeader1>
        </CenterHead>
        {/* <StyledParagraph className="card-intro-description">
          A short story how hacking my grandparents' router led me into computer
          science:
        </StyledParagraph> */}
        <StyledParagraph>
          One evening, as my father eagerly lent a hand in setting up my
          grandparents' new Roku device, a hiccup emerged - my grandparents
          couldn't recall their Wi-Fi password. With my father stumped, I was
          tasked with retrieving the password.
        </StyledParagraph>
        <MarginVert8 />
        <StyledParagraph>
          Leveraging a router login page default password exploit to retrieve
          the Wi-Fi password, my interest in computer science was ignited...
        </StyledParagraph>
        {/* <StyledParagraph className="card-intro-description">
          However, there's a downside: my friends are now cautious whenever I
          visit their homes.
        </StyledParagraph>
        <StyledParagraph className="card-intro-description">
          Learn more about my journey on my <a href="">blog</a>.
        </StyledParagraph> */}
        <StyledButtonContainer>
          <TextButton
            className="axios-text-button"
            style={{
              position: "absolute",
              bottom: 16,
              left: 16,
            }}
            onClick={() => window.open("https://alexbadia.medium.com/")}
            hoverWidth={`112px`}
            isHovered={isHovered}
            tools={[<StyledLink>Read More</StyledLink>]}
          />
        </StyledButtonContainer>
      </StyleContainer>
    </Card>
  );
};
