import { useState } from "react";
import styled from "styled-components";

import { APPLE_WHITE } from "../../constants";

import { Card, CardStyle, MOBILE_CARD_WIDTH } from "./Card";
import { TextButton } from "../Buttons/TextButton";
import { StyledLink } from "../Typography/Typography";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

interface CardSdfsProps {
  isGrabbing: boolean;
}

export const CardSdfs = ({ isGrabbing }: CardSdfsProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      cardStyle={CardStyle.SQUARE_1}
      xsHeight={MOBILE_CARD_WIDTH}
      backgroundColor={APPLE_WHITE}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isGrabbing={isGrabbing}
    >
      <StyledContainer>
        <StyledImage
          src="https://storage.googleapis.com/g-i-f-s/t-h-r-e-e-d-c-h-e-s-s-1-1.gif"
          alt="chess-three-d-thumbnail"
        />
        <StyledButtonContainer>
          <TextButton
            className="sdfs-text-button"
            style={{
              position: "absolute",
              bottom: 16,
              left: 16,
            }}
            onClick={() =>
              window.open("https://github.com/alexbadia1/Chess3D")
            }
            hoverWidth={`104px`}
            isHovered={isHovered}
            tools={[<StyledLink>3D Chess</StyledLink>]}
          />
        </StyledButtonContainer>
      </StyledContainer>
    </Card>
  );
};
